.results-for{
  font-size: 22px;
  line-height: 25px;  
}

.results-options{
  font-size: 20px;
  line-height: 23px;
}

.home-data-results{

  .project{

    .project-title{

      h3{
        font-size: 24px;
        line-height: 25px;
      }

      div{
        text-align: left;
      }
    }

    .project-details{
      text-align: left;

      .highlight{
        font-size: 18px;
        line-height: 19px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 14px;

        div{
          text-align: left;
          padding-left: 0;
          padding-right: 0;
        }

      }

      .sub-highlight{
        font-size: 16px;
        line-height: 17px;
        margin-left: 0;
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 14px;

        div{
          text-align: left;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    
    a.expand-project-details{
      width: 100%;
    }

    .project-details-expanded{

      .home-data-expanded-project-details-results{

        .batch-results{

          .result{
            text-align: left;
            float: none;
            background: none;
            border-bottom: 1px solid @lighter-grey;
            padding-bottom: 15px;
            margin-bottom: 15px;

            .result-title{
              font-size: 18px;

              .awarded{
                text-align: left;
                font-size: 16px;
              }

              .col-sm-12{
                div{
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }

            .result-table{
              
              .col-sm-12{
                padding-left: 0;
                padding-right: 0;

                .table-responsive{
                  border: 1px solid #CBCFDC;

                
                  thead{
                    background-color: transparent;
                  }

                  tbody{
                    background-color: #F5F5F5;
                  }

                }
              }

              .table-hover{
                
                &>tbody{

                  &>tr{

                    &:hover{
                      background-color: #F5F5F5;
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}