.gallery-carousel{

  .carousel-control.orange-box-arrow{
    position: absolute;
    bottom: 107px;
    left: auto;
    right: auto;
    top: auto;
    opacity: 1;
    background-image: none;
    filter: none;
    height: 31px;
    width: 40px;
    margin-top: 30px;
    z-index: 8000;

    &.left{
      left: 0;
      min-width: 0;

      svg{
        margin-right: 5px;
        right: 0;
        left: auto;
      }
    }

    &.right{
      right: 0;

      svg{
        margin-left: 2px;
        right: auto;
        left: 0;
      }
    }

    svg{
      bottom: 0;
      right: 0;
      position: absolute;
      fill: @dark-orange;
    }

    &:hover{

      svg{
        fill: darken(@dark-orange, 10);
      }
    }
  }
  .carousel-inner{

    .item{
      margin-top: 40px;

      .img-wrap{
        border: 1px solid @light-grey;
        text-align: center;
        margin-bottom: 30px;

        img{
          height:600px;
        
        }
      }

      &.left{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      &.right{
        margin-top: 40px;
        width: 100%;
        overflow: visible;
        float: none;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .caption{
      padding-left: 5px;
      padding-right: 5px;
      min-height: 100px;

      p{
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .social-liking{
        padding-top: 10px;
      }
    }

  }

  .thumbnails{
    margin-top: 10px;
    padding-top: 25px;
    border-top: 1px solid @light-grey;

    ul.carousel-indicators{
      .plain-list();
      position: relative;
      bottom: auto;
      left: auto;
      z-index: 15;
      width: 100%;
      padding-left: 0;
      margin-left: 0;
      text-align: center;
      list-style: none;

      li{
        margin-bottom: 20px;
        text-align: center;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;
        display: inline-block;
        width: 25%;
        height: auto;
        margin: 0 0 20px;
        text-indent: 0;
        cursor: pointer;
        background-color: none;
        border: none;
        border-radius: 0;

        .img-wrap{
          border: 1px solid @light-grey;
          width: 100%;

          img{
            max-width: 100%;
            opacity:.7;
          }
        }
        
        &.active{

          .img-wrap{

            img{
              opacity:1;
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width: 1220px) {
  
  .gallery-carousel{

    .carousel-inner{

      .item{

        .img-wrap{

          img{
            height:auto;
            max-width: 100%;
          }
        }
      }

      .caption{
        min-height: 120px;
      }
    }

    .carousel-control.orange-box-arrow{
      bottom: 115px;
    }
  }
}