.ff-helvetica {
  font-family: Helvetica, 'Helvetica Neue', Arial, sans-serif;
}

.ff-body {
  .ff-lora;
}

.ff-header {
  .ff-open-sans;
}

.ff-open-sans{
	font-family: 'Open Sans', sans-serif;
}

.ff-lora{
	font-family: 'Lora', serif;
}