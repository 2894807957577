.page-content .main-content{

  .map{
    height: 350px;
  }
  .left{
    padding-right: 30px;
    padding-left: 15px;
    width: 30%;
    min-width: 220px;
    float: left;
    margin-right: 0px;
    
    .members-search{
      margin-top: 20px;
      background-color: rgba(182, 201, 224, 0.4);
      padding-top: 25px;
      padding-bottom: 20px;

      h3{
        padding-top: 0;
      }
 
      form{
        padding-top: 20px;

        label{
          color: #1f1f1f;
          font-weight: 700;
          font-size: 16px;
          line-height: 19.2px;
          .ff-open-sans();
        }

        input, select{
          border: none;  
        }

      }
    }

    .print-members-list{
      margin-top: 20px;
    }
  }
  
  .right{
    margin-top: 20px;
    width: auto;
    overflow: hidden;
    float: none;
    padding-right: 0;
    
    h2{
      padding-top: 5px;
      margin-bottom: 25px;
      color: #002c5d;
      font-weight: 300;
      font-size: 32px;
      line-height: 38.4px;
      text-align: left;

      &.member-results{
        margin-bottom: 0;
      }
    }

    .sort-options{
      font-size: 15px;
      margin-bottom: 35px;

      a{
        margin-right: 10px;

        &.current-sort{
          display: none;
        }
      }
    }
    
    .members-search-results{

      .batch-results{
        .result{
          color: #000000;
          margin-bottom: 35px;
          padding-bottom: 30px;
          font-weight: 400;
          font-size: 15px;
          line-height: 21px;
          .ff-lora();
          border-bottom: 1px solid @light-grey;
          
          h4{
            color: #002c5d;
            font-weight: 700;
            font-size: 22px;
            line-height: 26.4px;
            margin-bottom: 5px;
          }

          a{
            color: #004c97;
            text-decoration: underline;
            color: #004c97;

            &:hover{
              color: darken(#004c97, 14);
            }
          }

          .bank-type{
            text-align: right;
            ul{
              .horizontal-list();

              li{
                border-right: 1px solid @light-grey;

                &:last-child{
                  border: none;
                }
              }
            }
          }
        }

        &:last-child{

          .result{

            &:last-child{
              border: none;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    button.load-more-members-data{
      text-align: left;
      padding-left: 15px;
      white-space: normal;

      &:disabled{

        &:hover{
          background-color: @lighter-orange;
          color: @dark-orange;
        }
      }
      
      .text-right, span.text-right{
        float: right;
        padding-right: 15px;
        color: #bf6b17;
        font-weight: 300;
        font-size: 16px;
        line-height: 19.2px;
        .ff-open-sans();
      }
      
      &:hover{

        .text-right{
          color: #FFF;
        }
      }
    }
    .members-search-next{
      display: none;
    }
  }
}

@media screen and (max-width: 1199px) {

  .page-content .main-content .right .members-search-results .batch-results .result{

    h4{
      margin-bottom: 0;
    }

    .bank-type{
      margin-bottom: 7px;
      margin-top: -3px;
      text-align: left;
      ul{
        .horizontal-list();

        li{
          border-right: 1px solid @light-grey;

          &:last-child{
            border: none;
          }
          // &:first-child{
          //   text-transform: lowercase;
          //   &:first-letter{
          //     text-transform: capitalize
          //   }
          // }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {

  .page-content .main-content{

    .left{

    }

    .right{
    
      button{

        span.secondary-text{
          font-size: 14px !important;
          display: block !important;
          text-align: left !important;
          float: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {

  .page-content .main-content{

    .left{

    }

    .right{

      .members-search-results .batch-results .result{

        ul{

          li{
            padding: 0;
            border: none;
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {

  .page-content .main-content{

    .print-members-list{
      margin-bottom: 30px;
    }

    .left{
      width: 100%;
      padding-right: 15px;
      
    }

    .right{
      width: 100%;
      padding-right: 15px;

      .members-search-results .batch-results .result{

        ul{

          li{
            padding: 0;
            border: none;
            display: block;
          }
        }
      }
    }
  }
}