.results-for{
  color: #7d858c;
  font-size: 28px;
  font-weight: 300;
  line-height: 33.6px;
  .ff-open-sans();
}

.results-options{
  color: #7d858c;
  font-weight: 300;
  font-size: 22px;
  line-height: 26.4px;
  .ff-open-sans();
  margin-bottom: 70px;
  
  a{
    color: #bf6c18;
    text-decoration: underline;
    
    &:hover{
      color: darken(#bf6c18, 15);
    }
  }
}

.home-data-results{

  .project{
    margin-top: 80px;

    .project-title{
      border-bottom: 1px solid @light-grey;
      padding-bottom: 4px;
      margin-bottom: 11px;

      h3{
        color: #002c5d;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        padding-top: 0;
      }

      a{
        color: #004c97;
        font-weight: 300;
        font-size: 16px;
        line-height: 19.2px;
        .ff-open-sans();
        text-decoration: underline;
        line-height: 36px;
        
        &:hover{
          color: darken(#004c97, 15);
        }
      }
    }

    .project-details{
      margin-bottom: 30px;
      
      .highlight{ 
        color: #212121;
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
        .ff-open-sans();
      }

      .sub-highlight{
        color: #666666;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        .ff-open-sans();
        margin-left: 25px;
      }
    }

    a.expand-project-details{
      .transition(0.5s);
      width: 300px;

      &.expanded{
        background-color: @dark-blue;
        color: #FFF;

        &:hover{
          background-color: darken(@dark-blue, 10);

        }
      }
    }

    .project-details-expanded{
      display: none;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: rgba(9, 44, 93, 0.1);
      
      .heading{
        padding-bottom: 30px;
        
        h3{
          padding-top: 0;
        }

        .filtered-by{
          font-weight: 300;
          font-size: 22px;
          line-height: 26.4px;
          .ff-open-sans();
          color: rgba(8, 44, 93, 0.6);
        }
        input{
          width: 97%;
          .ff-open-sans();
          border: none;
        }

        span.input-group-btn{
          margin-left: 5px;
        }
      }
      
      .home-data-expanded-project-details-results{

        .result{
          background: #FFF;
          padding-top: 20px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          
          .result-title{
            color: #092c5d;
            font-weight: 700;
            font-size: 26px;
            line-height: 24px;
            .ff-open-sans();
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid @light-grey;
            
            .awarded{
              color: #000000;
              
              span{
                color: #666666;
                font-weight: 300;
              }
            }
          }
          
          .result-table{

            table{
              margin-bottom: 0;
              
              thead{

                th{
                  color: #666666;
                  .ff-open-sans();
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 15.4px;
                }
              }
              
              tbody{
                td{
                  color: #000000;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 21px;
                  .ff-lora();
                }
              }

              td, th{
                &:first-child{
                  width: 20%;
                }

                &:nth-child(2){
                  width: 7%;
                }

                &:nth-child(3){
                  width: 10%;
                }

                &:nth-child(4){
                  width: 17%;
                }

                &:nth-child(5){
                  width: 23%;
                }

                &:nth-child(6){
                  width: 23%;
                }
              }
            }
          }
        }
      }

      button.load-more-home-data{

        &:disabled{

          &:hover{
            background-color: @lighter-blue;
            color: @dark-blue;
          }
        }
        
        span{
          font-weight: 300;
        }
        
      }
      .home-data-next{
        display: none;
      }
    }
  }
}


@media screen and (max-width: 450px) {

  .page-content .home-data-results .project-details-expanded{
    
    button{

      span{
        display: none;
      }

    }
  }
}
