.member-spotlight-title{
  display: table;
}

.quotes-carousel{
  position: relative;
  height: auto;
  width: 100%;
  top: auto;
  left: auto;
  display: block;
  float: none;

  .carousel-inner{
  	float: none;

  	.item{
  		float: none;

  		.bg-image{
  			height: auto;
  			position: relative;
  			background-size: auto 300px;
  			background-repeat: no-repeat;
  			background-position: 14% top;
        height: 300px;
  		}

			.orange-box{
				margin-top: 300px;
				top: 0;
				position: relative;
				height: auto;
				min-height: 380px;
				padding-right: 10px;
				padding-left: 10px;

				h1{
					font-size: 28px;
				}
  		}
  	}
  }
}

.events{
  margin-top: 25px;
}