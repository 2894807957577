.mobile-nav{
	position: absolute;
	top:0;
	left: 0;
	height: 110px;
	width: 100%;
	background-color: rgba(0, 44, 93, 0.9);
	z-index: 1;

	.logo-div{
		position: absolute;
		width: 100%;
		text-align: center;
		top: 10px;

		a{

			svg{
				// width: 300px;
			}
		}
	}

	.hamburger{
		position: absolute; 
		top: 5px;
		right: 15px;

		i{
			font-size: 40px;
			z-index: 2;
			color: #FFF;
			.transition();

			&:hover{
				color: @dark-orange;
			}
		}

	}

}

.mobile-nav-content {
  position: fixed;
  height: 200%;
  top: 110px;
  right: -100%;
  left: auto;
  background: @light-blue-mix;
  // max-width: 250px;
  width: 100%;
  z-index: 5;
  text-align: left;
  display: block;


	.main-mobile-nav{
		
		ul{
			.plain-list();
			border-bottom: 1px solid @lightest-blue;

			li{
				border-top: 1px solid @lightest-blue;

			  a {
				  color: white;
				  text-decoration: none;
				  .ff-open-sans();
				  display: block;
				  padding: 8px 10px;
				  font-size: 18px;


			    &:hover, &.current{
			    	background: @dark-blue;
			    }
			  }


			  &.has-children{

			  	a{

			  		svg{
			  			float: right;
			  			width: 10px;
			  			fill: #FFF;
			  		}
			  	}

				  .sub-mobile-nav{
						position: fixed;
						height: 100%;
						top: 110px;
						right: -100%;
						background: @light-blue-mix;
						// max-width: 250px;
						width: 100%;
						z-index: 5;
						text-align: left;
						display: block;

				  	ul{
				  		border-bottom: 1px solid @lightest-blue;

					  	li{

					  		a{

									&.back{

										svg{
											float: left;
											width: 10px;
											height: 22px;
											margin-right: 9px;

										}
									}
					  		}
					  	}
				  	}

				  }
			  }

			  form{
			  	color: #FFF;

			  	padding-top: 0;
			  	padding-bottom: 0;

			  	input[type="text"]{
			  		background-color: transparent;
			  		border: none;
			  		color: #FFF;
			  		.ff-open-sans();
			  		font-size: 18px;
			  		height: 39px;
			  	}

			  	.input-group-btn{
						
						button{
							margin-right: 0;
							.rounded(0px);
							border: none;
							.ff-open-sans();
							font-weight: 700;
							padding: 10px 20px;
						}
			  	}
			  }
			}
		}
	}
}
