form.home-data-search{
  margin-top: 0;
  
  .row{
    margin-right: 0;
    margin-left: 0;

    .form-column{
      padding-left: 0;
      padding-right: 0;
      border-right: none;

      h3{
        margin-top: 40px;
      }
    }
  }

  input.btn-light-orange{
    width: 100%;
  }
}