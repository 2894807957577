form.home-data-search{
  padding-top: 0;
  margin-top: 50px;
  
  .row{
    h3{
      margin-bottom: 15px;
      padding-top: 0;
    }


    label{
      font-weight: 700;
        
      span{
        font-weight: 300;
      }
    }

    .form-column{
      padding-left: 25px;
      padding-right: 25px;
      border-right: 1px solid @light-grey;
      
      &:last-child{
        border: none;
      }
    }
  }

  input.btn-light-orange{
    width: 200px;
    margin: 30px auto 0;
    display: block;
  }
}