.big-main-image{
  position: relative;
}
.page-content{
  margin-top: 0;
  padding-bottom: 30px;

  &.no-image{
    padding-top: 110px;
  }

  .sidebar{
    display: none;
    width: 100%; 
    float: none;    
    width: 100%;
    max-width: 100%;
  }
  .main-content{
    width: 100%;
    display: block;
    padding-top: 20px;

    h1{
      font-size: 32px;
      line-height: 34px;
    }
    
    .sidebar-right{
      margin-left: -15px;
      margin-right: -15px;
      
      &.pull-up{
        padding-top: 40px;
      }
    }

    .row{

      .sidebar-right{
        margin-left: 0;
        margin-right: 0;
        
        &.pull-up{
          padding-top: 40px;
        }
      }
    }

  }
}

.btn-light-orange{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
