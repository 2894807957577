.big-main-image.home-image{
  // background-image: url(/assets/images/home-main-image.png);
}
.home-carousel{
  position: absolute;
  height: 706px;
  width: 100%;
  top: 0;
  left: 0;
}
.green-box{
  background-color: rgba(87, 92, 63, 0.9);
  height: 377px;
//  background-image: url(/assets/images/green-box-right-arrow.png);
  background-image: url(/assets/images/green-box-grey-corner.png);
  background-position:  bottom right;
  background-repeat: no-repeat;
  position: relative;
  top: 226px;
  padding: 30px 40px;
  z-index: 1;
  
  h1{
    color: #ffffff;
    font-weight: 300;
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  p{
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    line-height: 23.4px;
    .ff-lora();
    margin-bottom: 25px;
  }

  a.green-box-arrow{
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    top: auto;
    opacity: 1;
    background-image: none;
    filter: none;
    width: 119px;
    height: 89px;
    
    i{
      bottom: 10px;
      right: 10px;
      position: absolute;
      font-size: 40px;
      color: @green;
    }

    svg{
      bottom: 18px;
      right: 10px;
      position: absolute;
      fill: @green;
    }
    
    &:hover{
      
      i{
        color: darken(@green, 10);
      }

      svg{
        fill: darken(@green, 10);
      }
    }
  }
}

.orange-navigation{
  margin-top: 566px;
  background: @orange;
  height: 82px;

  ul{
    .horizontal-list(@padding:0);
    text-align: center;

    li{

      a.orange-nav-link{
        color: rgba(255, 255, 255, 0.6);
        .ff-open-sans();
        font-size: 24px;
        font-weight: bold;
        line-height: 82px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        overflow: hidden;
        height: 58px;
        .transition();
          
        &:before{
          content: ' ';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0;
          background-image: url(/assets/images/main-navigation-hover-bg.png);
          background-repeat: no-repeat;
          background-position: bottom center;
          .transition(0.3s);
          height: 58px;
        }

        &:hover,
        &.active{
          text-decoration: underline;
          color:white;

          &:before {
            opacity: 1;
          }
        }
      }

      &.active{

        a{
          text-decoration: underline;
          color:white;

          &:before {
            opacity: 1;
          }
        }
      }

      &:last-child,
      &:first-child{
        a{
          border: none;
          padding: 0;
          margin: 0;
        }
      }

    }
  }
}

.section-detail{
  padding: 80px 0;

  h3{
    color: @dark-grey;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    .ff-open-sans();
  }

  p{
    color: @blue;
    color: #144797;
    font-size: 26px;
    line-height: 32px;
    font-weight: 300;
    .ff-open-sans();
  }
  
  a.btn-light-orange{
    margin-top: 15px;
  }
  
  ul{
    padding-top: 30px;
    
    li{
      // list-style-image: url(/assets/images/+.png);
      padding-bottom: 8px;
      
      a{
        color: @darker-orange;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        .ff-open-sans();
        text-decoration: underline;
        padding: 5px 0;
        
        &:hover{
          color: darken(@darker-orange, 15);
        }
      }
    }
  }

}

.news-highlight{
  background: url(/assets/images/news-highlight.png);
  background-size: cover;
  background-position: center;
  text-align: center;
  height: 283px;
  padding-top: 64px;
  
  h2{
    font-weight: 300;
    font-size: 44px;
    line-height: 52.8px;
    color: #ffffff;
  }
  
  h3{
    font-weight: 300;
    font-size: 28px;
    line-height: 33.6px;
    color: #ffffff;
  }

  a.highlight-link{
    margin-top: 20px;
  }
}