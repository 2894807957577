.big-main-image.members-image{
  // background-image: url(/assets/images/members-main-image.png);
  // height: 520px;
}

.blue-box{
  background-color: #007dba;
  height: 300px;
  top: 180px;
  padding: 25px 5px;  

}

.member-spotlight-title{
  display: none;
}

.bank-highlight{
  height: 330px;
  margin-bottom: 60px;
  
  .bg-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 330px;
  }
  .orange-box{
    background-color: rgba(193, 108, 24, 0.9);
    height: 330px;
    background-image: url(/assets/images/orange-box-grey-corner.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 20px;
    position: absolute;
    
    h2{
      color: #ffffff;
      font-weight: 700;
      font-size: 32px;
      line-height: 38.4px;
      text-align: left;
      margin-bottom: 0;
    }
    
    h3{
      color: #ffffff;
      font-weight: 300;
      font-size: 24px;
      line-height: 28.8px;
      margin-bottom: 20px;
      padding-top: 0;
    }
    
    p.quote{
      color: #ffffff;
      font-size: 20px;
      font-style: italic;
      line-height: 24px;
      .ff-open-sans();
      font-weight: 300;
      margin-bottom: 25px;
    }
    
    a.orange-box-arrow{
      position: absolute;
      bottom: 0;
      right: 0;
      left: auto;
      top: auto;
      opacity: 1;
      background-image: none;
      width: 119px;
      height: 89px;

      i{
        bottom: 10px;
        right: 10px;
        position: absolute;
        font-size: 40px;
        color: @dark-orange;
      }

      svg{
        bottom: 18px;
        right: 10px;
        position: absolute;
        fill: @dark-orange;
      }

      &:hover{

        i{
          color: darken(@dark-orange, 10);
        }

        svg{
          fill: darken(@dark-orange, 10);
        }
      }
    }
  }

  
  #carousel{
    .carousel-inner{
      .item{

        &.left{
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }

      }
    }
  }
}

.events{
  
  h3{
    color: #002c5d;
    font-weight: 300;
    font-size: 28px;
    line-height: 33.6px;
    margin-bottom: 15px;
    padding-top: 0 !important;
  }
  
  .row{
    margin-bottom: 20px;
  }
  
  a.btn-light-orange{
    margin-top: 4px;
  }
  
  .event-row{
    margin-bottom: 18px;
    min-height: 50px;
    
    .calendar-icon{
      background: url(/assets/images/calendar-icon.png);
      width: 46px;
      height: 46px;
      text-align: center;
      color: #b0c3da;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      .ff-open-sans();
      vertical-align: top;
      padding: 15px 0 0 0;
      float: left;
      margin-right: 13px;
    }

    .event-content{
      width: auto;
      overflow: hidden;

      a.title-link{
        margin-bottom: 3px;
      }
      
      p.details{
        color: #4d5459;
        font-weight: 400;
        font-size: 14px;
        line-height: 16.8px;
        .ff-open-sans();
      }
    }
  }
}

@media screen and(min-width: 991px) and (max-width: 1150px) {
  .bank-highlight{
    
    .orange-box{
      
      h3{
        margin-bottom: 10px;
      }

      p.quote{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 15px;
      }
    }
  }
}
