// Configuration
@import 'config/_variables';
@import 'config/_typography';

// Vendor
@import 'vendor/_normalize';
@import 'vendor/_fonts';
// @import (inline) 'vendor/bootstrap.min.css';
// @import (inline) 'vendor/font-awesome-4.3.0/css/font-awesome.min.css';

// Mixins
@import 'mixins/_common';

// Visual 
@import 'modules/_elements';

// Layouts
@import 'views/layouts/_master';

// Partials
@import 'views/partials/_header';
@import 'views/partials/_footer';
@import 'views/partials/_member-login';
@import 'views/partials/_left-sidebar';
@import 'views/partials/_right-sidebar';

// Pages
@import 'views/pages/_page-default';
@import 'views/pages/_page-home';
@import 'views/pages/_page-members';
@import 'views/pages/_page-members-search';
@import 'views/pages/_page-index';
@import 'views/pages/_page-contact';
@import 'views/pages/_page-calendar';
@import 'views/pages/_page-search';
@import 'views/pages/_page-gallery';
@import 'views/pages/_page-home-data';
@import 'views/pages/_page-home-data-results';

// Adaptive
@media screen and (max-width: 767px) {
  // Visual
  @import 'modules/_elements@768';

  // Mixins
  @import 'mixins/_common@768';

  // Layouts
  @import 'views/layouts/_master@768';

  // Partials
  @import 'views/partials/_header@768';
  @import 'views/partials/_footer@768';

  // Pages
  @import 'views/pages/_page-home@768';
  @import 'views/pages/_page-members@768';
  @import 'views/pages/_page-default@768';
  @import 'views/pages/_page-calendar@768';
  @import 'views/pages/_page-gallery@768';
  @import 'views/pages/_page-home-data@768';
  @import 'views/pages/_page-home-data-results@768';
  @import 'views/pages/_page-members-search@768';
}

// Print
@media print{
  @import 'modules/_print';
}