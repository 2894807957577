.gallery-carousel{

  .carousel-control{
    display: none;
  }

  .thumbnails{
    display: none;
  }

  .carousel-inner{

    .item{
      display: block;

      .caption{
        padding-left: 0;
        margin-left: 0;
        padding-right: 0;
        margin-right: 0;
        width: 100%;
        float: none;
        min-height: 0;
      }

      .social-liking{
        display: none;
      }
    }
  }
}