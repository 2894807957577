.page-content .main-content{

  .print-members-list{
    display: none;
  }

	.left{
		padding-left: 0;
		padding-right: 0;

		.row{
			padding-left: 15px;
			padding-right: 15px;
		}

	  .map{
	  	margin-top: 20px;
	  	margin-bottom: 20px;
	  	width: 100%;
	  	height: auto;

	  	#map-canvas{
	  		height: 375px;
	  		width: 100%;
	  	}
	  }
	}
  
  .right{
  	padding-left: 0;
  	padding-right: 0;
  }
}
