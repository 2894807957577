.big-main-image{
  position: absolute;
  top: 0;
  left: 0;
  // background-image: url(/assets/images/home-main-image.png);
  background-position: center;
  background-size: cover; 
  height: 706px;
  width: 100%;
}

.page-content{
  margin-top: 440px;
  padding-bottom: 50px;

  &.no-image{
    margin-top: 0;
    padding-top: 80px;
  }

  .sidebar{
    float: left;
    min-width: 235px;
    max-width: 265px; 
    width: 25%;     
  }
  .main-content{
    width: auto;
    overflow: hidden;
    padding-left: 25px;

    h1{
      color: #002c5d;
      font-weight: 300;
      font-size: 42px;
      line-height: 50.4px;
      .ff-open-sans();
      margin-bottom: 20px;
    }

    h2{
      color: #7d858c;
      font-weight: 300;
      font-size: 28px;
      line-height: 33.6px;
      text-align: center;
      margin-bottom: 40px;
    }
    
    h3{
      color: #002c5d;
      font-weight: 300;
      font-size: 28px;
      line-height: 33.6px;
      padding-top: 50px;
    }

    p{
      color: #222222;
      font-weight: 400;
      font-size: 15px;
      line-height: 19.5px;
    }

    &.default{

      h2, h3, h4, h5, h6{
        margin-bottom: 10px;
        text-align: left;
        padding-top: 0;
      }

      ul.orange-list, ul.black-list{
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 10px;
      }

      a.btn-light-orange, a.btn-blue{
        display: inline-block;
        margin-top: 0;
        margin-bottom: 10px;

      }

    }

    .content-block{
      padding-bottom: 25px;

      img{
        max-width: 100%;
        width: 100%;
      }
      a.title-link{
        margin: 15px 0 12px;
      }

      p{
        color: #1f1f1f;
      }
      
      a.btn-light-orange{
        margin-top: 20px;
      }
    }

    &.left{
      padding-left: 0;
    }
  }

  ul.orange-list{
    padding-top: 30px;
    .plain-list();
    
    li{
      padding-bottom: 13px;
      
      a{
        color: @darker-orange;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        .ff-open-sans();
        text-decoration: underline;
        padding: 5px 0;
        
        &:hover{
          color: darken(@darker-orange, 15);
        }
      }
    }
  }

  ul.black-list{
    padding-top: 15px;
    .plain-list();
    
    li{
      color: #202020;
      .ff-lora();
      font-weight: 600;
      font-size: 15px;
      line-height: 25.5px;
      
      span{
        font-weight: 400;
        color: #6e797f;
      }
      
      a{
        font-weight: 400;
        color: #144797;
        text-decoration: underline;
        
        &:hover{
          color: darken(#144797, 15);
        }
      }
    }
  }

  ul.disc{
    list-style: none;

    li{
      background: url(/assets/images/disc.png) no-repeat 0 10px;
      padding-left: 14px;
      margin-left: 5px;
    }
  }

  hr{
    border-top: 1px solid @light-grey;
    margin-top: 40px;
    margin-bottom: 10px;
  }

}

ul.plus{

  li{
    list-style-image: url(/assets/images/+.png);
    margin-left: 17px;
  }
}

ul.disc{
  list-style: none;

  li{
    background: url(/assets/images/disc.png) no-repeat 0 8px;
    padding-left: 14px;
    margin-left: 0;
  }
}

.content-results{
  
  a{
    color: @dark-orange;

    &.title-link{
      font-weight: 700;
      font-size: 18px;
      line-height: 21.6px;
      .ff-open-sans();
      text-decoration: underline;
      display: block;
    }

    &:hover{
      color: darken(@dark-orange,12);
    }
  }

  .result{
    margin-bottom: 20px;

    p.details{
      color: #4d5459;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      font-family: 'Open Sans', sans-serif;
    }
  }
}