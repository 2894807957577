.main-calendar{

  .showing-events{

    .event-month{

      .event-row{
        padding-left: 15px;
        padding-right: 15px;

        .calendar-icon{
          float: none;
          display: block;
          margin: 0 auto;
          margin-bottom: 8px;
        }

        .event-content{

          a.title-link{
            text-decoration: none;
          }
        }
      }
    }
  }

}