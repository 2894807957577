.member-login{
  
  h3{
    color: #ffffff;
    font-weight: 300;
    font-size: 26px;
    line-height: 31.2px;
    margin-bottom: 15px;
  }
  
  input{
    border-radius: 0;
    margin-bottom: 14px;
    height: 43px;
    line-height: 43px;
    color: #082c5d;
    font-weight: 300;
    font-size: 16px;
    line-height: 19.2px;
    .ff-open-sans();
    
    &:input-placeholder {  
       color: #FF0000;
    }
  }
  
  button{
    width: 100%;
    margin-bottom: 0;
  }
  
  p{
    color: #bad8ea;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
    .ff-open-sans();
    border-top: 1px solid rgba(0, 54, 106, 0.3);
    margin-top: 0;
    padding-top: 6px;
    
    a{
      color: #bad8ea;
      text-decoration: underline;
      
      &:hover{
        color: #FFF;
      }
    }
  }
}