body,
html,
.page-content{
  width: 100%;
}

.mobile-nav,
.mobile-nav-content,
.main-mobile-nav,
.header,
.footer-main,
.footer-bottom,
#carousel,
.blue-box,
.sidebar,
.bank-highlight
{
  display: none !important;
}

.page-content{
  padding-top: 10px;
  margin-top: 0;

  &.no-image{
    padding-top: 10px;
  }
}

.orange-navigation{
  margin-top: 10px;
}
