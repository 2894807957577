.footer-top{
  padding-top: 60px;
  padding-bottom: 60px;
  
  h4{
    font-size: 28px;
    line-height: 33.6px;
    font-weight: 300;
    color: #002c5d;
    margin-bottom: 15px;
  }
  
  .left{
    padding-left: 0;
    
    a.news-link{
      font-size: 15px;
      color: @darker-blue;
      font-weight: 400;
      line-height: 19.5px;
      text-decoration: underline;
      margin-bottom: 25px;
      .ff-lora();
      display: block;
      
      &:hover{
        color: darken(@darker-blue, 12);
      }
    }
  }
  
  .center{
    
    p{
      color: #212121;
      font-weight: 400;
      font-size: 15px;
      line-height: 19.5px;
      .ff-lora();
      margin-bottom: 20px;
    }
  }
  
  .right{
    
    .quote{
      color: #7a858c;
      font-size: 24px;
      line-height: 28.8px;
      font-style: italic;
      font-weight: 300;
      .ff-open-sans();
    }
    
    .annotate{
      color: #4e5559;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      .ff-open-sans();
      margin-top: 5px;
    }
  }
}

.footer-main{
  // background: url(/assets/images/footer-main-bg.png);
  // background: url(/assets/images/footer-bg.jpg);
  background: url(/assets/images/footer-background-repeatable.jpg);
  background-size: 1242px 285px;
  background-position: center top;
  background-repeat: repeat;
  // background-repeat: no-repeat;
  min-height: 265px;
  padding-top: 20px;
  padding-bottom: 30px;

  .container{
    width: 100%;
    max-width: 1150px;
    

  
    .logo{
      padding-left: 40px;
      width: 45px;
      float: left;
      margin-right: 50px;
    }
    
    .footer-right{
      width: auto;
      overflow: hidden;
      padding-top: 30px;

      .left{
        
        h5{
          color: #ffffff;
          font-weight: 700;
          font-size: 18px;
          line-height: 21.6px;
          margin-bottom: 4px;
        }
        
        p.address{
          color: #ffffff;
          .ff-open-sans();
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
        }
        
      }

      .right-links{
        padding-right: 0;
      
        .row{
          margin-right: 0;

          .footer-links{
            padding-right: 0;

            h6{
              color: #ffffff;
              font-weight: 700;
              font-size: 15px;
              line-height: 18px;
              margin-bottom: 6px;
            }
            
            a{
              color: #bbd1e9;
              font-weight: 400;
              font-size: 14px;
              line-height: normal;
              .ff-open-sans();
              display: block;
              margin-bottom: 4px;
              
              &:hover{
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.footer-bottom{
  color: #b6c9df;
  font: 400 13px / 19.5px OpenSans;
  font-weight: 400;
  font-size: 13px;
  line-height: 50px;
  .ff-open-sans();
  height: 50px;
  background: #004c97;
}



@media screen and (max-width: 1165px) {

  .footer-main{
    background-position: -73px top;

    .container{
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;

      .logo{
        padding-left: 11px;
        width: 45px;
        float: left;
        margin-right: 5px;
      }
    }
  }
}