.sidebar-right{

  .right-sidebar{
    background-color: rgba(182, 201, 224, 0.4);
    padding: 20px 18px;
    margin-top: 40px;

    h3{
      padding-top: 0 !important;
      padding-bottom: 20px;
    }

    h6{
      color: #1f1f1f;
      font-weight: 700;
      font-size: 16px;
      line-height: 19.2px;
    }

    p{
      color: #1f1f1f;
      font-weight: 400;
      font-size: 15px;
      line-height: 19.5px;
      .ff-lora();
      padding-bottom: 3px;
    }
    
    .pretty-links{
      a{
        color: @dark-orange;
        font-weight: 400;
        line-height: 26px;
        .ff-open-sans();
        text-decoration: none;
        display: block;
        text-transform: capitalize;

        &:hover{
          color: darken(@dark-orange,12);
          text-decoration: underline;
        }
      }
    }

  }

  &.pull-up{

    .right-sidebar{
      margin-top: 0;
    }
  }

}