.list-page{
	padding-top: 50px;
	padding-bottom: 100px;

	h2{
		margin-bottom: 8px;
	}

	ul{
		.plain-list();

		li{
			margin-bottom: 5px;
		}
	}
}
