.bank-highlight{
	height: auto;
}
	
.home-carousel{
  position: relative;
  height: auto;
  width: 100%;
  top: auto;
  left: auto; 
  display: block;
  float: none;

  .carousel-inner{
  	float: none;

  	.item{
  		float: none;

  		.big-main-image{
  			height: auto;
  			position: relative;
  			background-size: auto 300px;
  			background-repeat: no-repeat;
  			background-position: top center;
  		}

  		.container{
  			padding-left: 0;
  			padding-right: 0;

  			.green-box{
  				margin-top: 300px;
  				top: 0;
  				position: relative;
  				height: auto;
  				min-height: 380px;
  				padding-right: 10px;
  				padding-left: 10px;

  				h1{
  					font-size: 28px;
  				}
  			}
  		}
  	}
  }
}

.orange-navigation{
	margin-top: 0;
	height: auto;

	ul{
		text-align: left;

		li{
			border-top: 1px solid #FFF;
			display: block;
			background-color: @orange;

			a.orange-nav-link{
				display: block;
				border: none;
				height: auto;
				text-align: left;
				line-height: normal;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 15px;
				padding-right: 15px;
				margin-left: 0;
				margin-right: 0;
				font-size: 18px;

				&:before{
					background-image: none;
				}

				svg{
					float: right;
					text-decoration: none;
					fill: rgba(255, 255, 255, 0.6);
				}

				&:hover{

					svg{
						fill: #FFF;
					}
				}
			}

			div{
				padding-top: 15px;
				padding-bottom: 15px;
				// display: none;
				background-color: #FFF;
				
				h3{
					font-size: 20px;
				}

				p{
					color: @blue;
					.ff-open-sans();
				}

				a.btn-light-orange{
					padding: 10px 18px;
					color: #c16c18;
					text-decoration: none;
					width: 100%;
					font-size: 15px;
					padding-left: 0;
					padding-right: 0;

					&:hover{
						color: #FFF;
					}
				}

				ul.disc{

					li{
						background-color: #FFF;

						a{
							padding-left: 15px;
							padding-bottom: 3px;
							color: @dark-orange;

							&:hover{
								color: darken(@dark-orange, 10%);
							}
						}
					}
				}
			}

			&.active{

				div{
					display: block;
				}

				a.orange-nav-link{

					svg{
						transform: rotate(90deg);
						fill: white;
					}
				}
			}

			&.showing{

				div{

					div{
						display: block;
					}

				}

				a.orange-nav-link{
					color:#FFF;
					text-decoration: underline;

					svg{
						transform: rotate(90deg);
						fill: white;
					}
				}
			}
		}
	}
}

.section-detail{
	display: none;
}

.news-highlight{

	h2{
		font-size: 30px;
		line-height: 30px;
	}

	h3{
		font-size: 22px;
		line-height: normal;
		margin-top: 10px;
	}
}