body {
  min-width: 0;
}

.container{
	width: 100%;

	.main-content{
		padding-left: 0;
	}
}

.title-link{
	text-decoration: none;
}

form{
	padding-top: 20px;
}

.main-content{

	.breadcrumb{
		margin-top: 20px;

		a{
			font-size: 14px;
		}

		.arrow-left{
			width: 10px;
			height: 14px;
		}
	}
}