.main-calendar{

  form.event-month-switcher{
    height: 62px;
    padding-top: 0;

      select.form-control{
        height: 62px;
        background: url(/assets/images/form-down-arrow.jpg) no-repeat right @orange;
        -webkit-appearance: none;
        background-position-x: 98%;
        line-height: 1;
        border: none;
        color: white;
        font-weight: 700;
        font-size: 32px;
        .ff-open-sans();

        option{
        }
      }
  }
  
  .dropdown{
    margin-bottom: 30px;
    
    button{
      width: 100%;
      background:@darker-orange;
      border: none;
      color: white;
      font-weight: 700;
      font-size: 32px;
      line-height: 62px;
      .ff-open-sans();
      text-align: left;
      padding-left: 20px;
      margin-top: 40px;

      // &:hover{
      //   background:@lighter-orange;
      //   color: @darker-orange;

      //   .icon{
      //     fill: @darker-orange;
      //   }
      // }
      
      
      .icon{
        float: right;
        fill: white;
        margin-right: 20px;
        margin-top: 17px;
        transform: rotate(90deg);
      }
    }
    
    ul{
      width: 100%;
      border-radius: 0;

      li{
        .ff-open-sans();
        font-size: 18px;
        line-height: 1;
        
        &:hover{
          
          a{
            background: @light-grey;
          }
        }
      }
    }
    
    &.open{
      
      button{
        
        .icon{
          transform: rotate(90deg);
        }
      }
    }
    
    button:hover{

      .icon{
        fill: @light-grey;
      }
    }
  }
  
	.event-month{

		.event-row{
			margin-bottom: 30px;
			padding-bottom: 40px;
			border-bottom: 1px solid @light-grey;

			&:last-child{
				border: none;
			}
		}

		h3{
			margin-bottom: 40px;
			padding-bottom: 10px;
			border-bottom: 1px solid @light-grey;
		}

    &.event-month-next{
      display: none;
    }
	}
}