.left-sidebar{
  padding-bottom: 30px;
  padding-right: 15px;
  padding-top: 8px;
  
  h2{

    a{
      color: #082c5d;
      font-weight: 400;
      font-size: 24px;
      line-height: 28.8px;
      margin-bottom: 12px;
      .ff-open-sans();
      padding-bottom: 12px;
      border-bottom: 1px solid @light-grey;
    }
  }
  
  a{
    color: @darker-grey;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    padding-bottom: 7px;
    .ff-open-sans();
    display: block;
//    margin-bottom: 14px;
    
    &.active{
      text-decoration: none;
      background-position: right 8px;
      background-image: url(/assets/images/sidebar-right-arrow.png);
      background-repeat: no-repeat;
      color: @blue;
    }

    &:hover{
      text-decoration: none;
      color: @blue;
    }
  }
}