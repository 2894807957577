.footer-top{

	div{
		margin-bottom: 40px;
		padding-left: 0;
		padding-right: 0;

		a.btn-light-orange{
			width: 100%;
		}
	}

}

.footer-main{
	background-position: center top;
	padding-top: 18px;

	.container{
		text-align: center;

		.logo{
			float: none;
			display: block;
			padding-left: 0;
			margin-right: 0;
			margin-left: -2px;
			width: 100%;
		}

		.footer-right{
			padding-top: 0;

			.left{

				h5{
					padding-bottom: 10px;
				}

				a.btn-grey-icon{
					margin-right: 6px;
				}
			}

			.right-links{
				padding-top: 0;
				margin-top: 30px;
				border-top: 1px solid @lighter-grey;
				padding-left: 0;

				.row{

					div.footer-links{
						padding-left: 0;
						padding-right: 0;

						h6{
							margin-top: 30px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
}

.footer-bottom{
	line-height: normal;
	height: auto;
	padding: 15px 10px;
}