.header{
  background-color: rgba(9, 44, 93, 0.9);
  height: 140px;
  z-index: 100;
  display: block;
  position: relative;
  border-bottom: 2px solid white;

  .logo-div{
    padding-top: 26px;

    img.main-logo{
      // background: url(/assets/images/main-logo.png) no-repeat;
      border: none;
      height: 84px;
      width: 126px;
    }
  }
  .top-nav{
    padding-top: 75px;

    ul{
      .horizontal-list(0 10px 4px);
      text-align: right;	

      li{

        a{
          color: #d6f2ff;
          font-weight: 400;
          font-size: 14px;
          line-height: 16.8px;
          .ff-open-sans();

          &:hover{
            color: white;
          }
        }
      }
    }
  }
  .main-nav{

    ul{
      .horizontal-list(0 10px);
      text-align: right;	

      li{
        position: relative;
        height: 41px;

        a{
          color: #ffffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 21.6px;
          .ff-open-sans();
          text-align: center;
          display: block;
          height: 40px;
          position: relative;
          overflow: hidden;
          color: @light-grey;
          
          &:before{
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            background-image: url(/assets/images/main-navigation-hover-bg.png);
            background-repeat: no-repeat;
            background-position: bottom center;
            .transition(0.3s);
          }
          
          &:hover,
          &.active{
            text-decoration: none;
            color:white;
          
            &:before {
              opacity: 1;
            }
          }
//          transition: background-image 0.8s ease-in-out;
//          background-position: bottom center;
          
//          &:hover,
//          &.active{
//            text-decoration: none;
//            background-image: url(/assets/images/main-navigation-hover-bg.png);
//            background-repeat: no-repeat;
//            background-position: bottom center;
//          }
        }
      }
    }
  }
}
