*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
}

body {
  .ff-body;
  font-size: @font-size;
  line-height: @font-line-height;
  color: @font-color;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4, 
h5,
h6 {
  .ff-header;   
  padding: 0;
  margin: 0;
}

a{
  outline: 0 !important;
}

select:focus::-ms-value {
  background-color: transparent;
  color: @font-color;

}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;  
  background-color: #ffffff; 
}

xmp{
  font-size: 12px;
  overflow-x: scroll;
}

.container{
  width: 92%;
  margin: 0 auto;
  max-width: 1280px;
}

.tight{
  padding-left: 4px;
  padding-right: 4px;
}

.no-padding{
  padding:0 !important;
  margin: 0 !important;
}


// Buttons

.btn-blue-handoff{
  .flat-button(@bg-color:@blue, @hover-bg-color: darken(@blue, 30), @border-color: darken(@blue,4), @hover-border-color: darken(@blue,30), @double-border:true, @double-border-color:lighten(@blue,20), @round:3px, @text-color:#FFF, @hover-text-color:#FFF, @padding:3px 25px, @font-size:14px);
}

.btn-green{
  .flat-button(@bg-color:@green, @hover-bg-color: darken(@green, 15), @border-color: darken(@green,4), @hover-border-color: darken(@green,30), @double-border:true, @double-border-color:lighten(@green,14), @round:3px, @text-color:#FFF, @hover-text-color:#FFF, @padding:3px 25px, @font-size:14px);
}

.btn-orange-icon{
  .flat-button(@bg-color:@orange, @hover-bg-color: darken(@orange, 8), @border-color: darken(@orange,14), @hover-border-color: darken(@orange,20), @double-border:true, @double-border-color:lighten(@orange,25), @round:3px, @text-color:white, @hover-text-color:white, @padding:3px 6px, @font-size:14px);
}

.btn-blue{
  .flat-button(@bg-color:@blue, @hover-bg-color: darken(@blue, 12), @border-color: darken(@blue,4), @hover-border-color: darken(@blue,30), @double-border:false, @double-border-color:lighten(@blue,20), @round:0, @text-color:#FFF, @hover-text-color:#FFF, @padding:14px 25px, @font-size:14px);
  font-weight: 600;
  line-height: 19.2px;
  font-size: 16px;
  .ff-open-sans();
  border: none;

  &:hover{
    text-shadow: none;
    border: none;
    color: #FFF;
  }
}

.btn-muted-blue{
  .flat-button(@bg-color:@muted-blue, @hover-bg-color: @lighter-blue, @border-color: darken(@muted-blue,4), @hover-border-color: darken(@muted-blue,30), @double-border:false, @double-border-color:lighten(@muted-blue,20), @round:0, @text-color:#FFF, @hover-text-color:@dark-blue, @padding:14px 25px, @font-size:14px);
  font-weight: 600;
  line-height: 19.2px;
  font-size: 16px;
  .ff-open-sans();
  border: none;
  text-shadow: none;

  &:hover{
    text-shadow: none;
    border: none;
    color: @dark-blue;
  }
}

.btn-dark-blue{
  .flat-button(@bg-color:@another-blue, @hover-bg-color: darken(@another-blue, 12), @border-color: darken(@another-blue,4), @hover-border-color: darken(@another-blue,30), @double-border:false, @double-border-color:lighten(@another-blue,20), @round:0, @text-color:#FFF, @hover-text-color:#FFF, @padding:8px 25px 7px, @font-size:14px);
  font-weight: 600;
  line-height: 19.2px;
  font-size: 16px;
  .ff-open-sans();
  border: none;

  &:hover{
    text-shadow: none;
    border: none;
    color: #FFF;
  }
}

.btn-orange{
  .flat-button(@bg-color:@orange, @hover-bg-color: darken(@orange, 8), @border-color: darken(@orange,14), @hover-border-color: darken(@orange,20), @double-border:false, @double-border-color:lighten(@orange,25), @round:0px, @text-color:white, @hover-text-color:white, @padding:10px 35px, @font-size:16px);
  font-weight: 600;
  line-height: 22px;
  text-shadow: none;
  .ff-open-sans();
  border: none;

  &:hover{
    text-shadow: none;
    border: none;
    color: #FFF;
  }
}

.btn-light-orange{
  .flat-button(@bg-color:@lighter-orange, @hover-bg-color: @orange, @border-color: @lighter-orange, @hover-border-color: darken(@lighter-orange,8), @double-border:false, @double-border-color:lighten(@lighter-orange,25), @round:0px, @text-color:@darker-orange, @hover-text-color:#FFF, @padding:10px 18px, @font-size:16px);
  font-weight: 600;
  line-height: 22px;
  text-shadow: none;
  .ff-open-sans();
  border: none;

  &:hover{
    text-shadow: none;
    border: none;
    color: #FFF;
  }
}

.btn-light-blue{
  .flat-button(@bg-color:@lighter-blue, @hover-bg-color: @dark-blue, @border-color: @lighter-blue, @hover-border-color: darken(@lighter-blue,8), @double-border:false, @double-border-color:lighten(@lighter-blue,25), @round:0px, @text-color:@dark-blue, @hover-text-color:#FFF, @padding:10px 18px, @font-size:16px);
  font-weight: 600;
  line-height: 22px;
  text-shadow: none;
  .ff-open-sans();
  border: none;

  &:hover{
    text-shadow: none;
    border: none;
    color: #FFF;
  }
}

.btn-clear{
  .flat-button(@bg-color:transparent, @hover-bg-color: transparent, @border-color: white, @hover-border-color: white, @double-border:false, @double-border-color:lighten(@lighter-orange,25), @round:0px, @text-color:white, @hover-text-color:#FFF, @padding:10px 18px, @font-size:16px);
  font-weight: 600;
  line-height: 22px;
  text-shadow: none;
  .ff-open-sans();

  &:hover{
    text-shadow: none;
    background-color: white;
    font-weight: 600;
  }

  &.green{

    &:hover{
      border-color: @light-green-hex;
      background-color: @light-green-hex;
      color: @green;
    }
  }

  &.orange{

    &:hover{
      border-color: @lighter-orange-hex;
      background-color: @lighter-orange-hex;
      color: @darker-orange;
    }
  }

  &.blue{

    &:hover{
      border-color: @blue-mix;
      background-color: @blue-mix;
      color: @darker-blue;
    }
  }
}

.btn-grey-icon{
  .flat-button(@bg-color:@grey, @hover-bg-color: @dark-blue, @border-color: @blue, @hover-border-color: darken(@blue,10), @double-border:false, @double-border-color:lighten(@grey,25), @round:3px, @text-color:@dark-blue, @hover-text-color:white, @padding:4px 8px 2px, @font-size:16px);
  text-shadow: none;
  margin-right: 4px;

  &:hover{
    text-shadow: none;
    color:white;
    border-color: white;
  }

}

.btn-social-liking{
  .flat-button(@bg-color:@grey, @hover-bg-color: @dark-blue, @border-color: @blue, @hover-border-color: darken(@blue,10), @double-border:false, @double-border-color:lighten(@grey,25), @round:3px, @text-color:white, @hover-text-color:white, @padding:6px 12px, @font-size:14px);
  text-shadow: none;
  border: none;
  margin-right: 9px;
  color: #ffffff;
  font-weight: 400;
  line-height: 8.8px;
  font-family: Arial;
  fill: #FFF;

  i{
    font-size: 16px;
    margin-right: 5px;
  }

  &:hover{
    text-shadow: none;
    color:white;
    border: none;
  }

  &.fb-like{
    background-color: @color-facebook;

    &:hover{
      background-color: darken(@color-facebook,18);
    }
  }

  &.tweet{
    background-color: @color-twitter;

    &:hover{
      background-color: darken(@color-twitter,14);
    }
  }

  &.g-plus{
    background-color: @color-google;

    &:hover{
      background-color: darken(@color-google,18);
    }
  }
}
.btn-full-width{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.btn-narrow{
  padding-left: 4px;
  padding-right: 4px;
}

.btn-tight{
  padding: 6px 12px;
}

.top-space{
  margin-top: 30px;
}

.bottom-space{
  margin-bottom: 30px;
}

a.title-link{
  color: @dark-orange;
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  .ff-open-sans();
  text-decoration: underline;
  display: block;

  &:hover{
    color: darken(@dark-orange,12);
  }
}

.line-behind-title{
  .line-behind-text(@light-blue);
}

svg.icon {
  display: inline-block;
  .transition();

  &.logo_header{
    width: 126px;
    height: 84px;
    fill: red;
  }

  &.logo_footer{
    width: 45px;
    height: 70px;
  }

  &.arrow_right{
    width: 20px;
    height: 25px;

  }

  &.fw-arrow{
    width: 20px;
    height: 25px;

    &.green{
      fill: @green;

      &:hover{
        fill: darken(@green, 12);
      }
    }
  }

  &.arrow-left{
    transform: rotate(180deg);

    &.grey{
      fill: #999999;
    }
  }

  &.calendar{
    width: 46px;
    height: 46px;
  }
}

/* 
 * Carousels
 */

.carousel-fade {
  .carousel-inner {
    .item {
      opacity: 0;
      transition-property: opacity;
    }

    .active {
      opacity: 1;
    }

    .active.left,
    .active.right {
      left: 0;
      opacity: 0;
      z-index: 1;
    }

    .next.left,
    .prev.right {
      opacity: 1;
    }
  }

  .carousel-control {
    z-index: 2;
  }
}
 
.carousel, 
.carousel-inner, 
.carousel-inner .item {
  height: 100%;
}


form{
  padding-top: 40px;

  .row{
    margin-bottom: 8px;
  }

  label{
    color: #202020;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    .ff-open-sans();
    
    &.required{
      font-weight: 700;
      
      span{
        font-weight: 300;
      }
    }
  }
}

input.form-control,
textarea.form-control,
select.form-control{
  border-radius: 0;
  border: 1px solid @darker-grey;
}

textarea.form-control{
  // height: 110px;
}

select.form-control{
  line-height: 50px;
  background: url(/assets/images/form-down-arrow.jpg) no-repeat 98% white;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 1;
  text-indent: 1px;
  text-overflow: '';

  option{
    // margin-top: 10px;
  }

  &::-ms-expand {
    display: none;
  }
}

.member-login{

  form{
    padding-top: 0;
  }
}

textarea:focus,
.form-control:focus,
select.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: rgba(87, 92, 63, 0.9);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px rgba(87, 92, 63, 0.6);
  outline: 0 none;
}

textarea,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {   
  padding-left: 10px;
  font-size: 16px;
}

select.form-control{
  padding-left: 5px;
  font-size: 16px;
  line-height: normal;
}

.padding-top{
  padding-top: 35px;
}

.breadcrumb{
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0;

  .arrow-left{
    width: 13px;
    height: 15px;
    fill: #999999;
  }

  a{
    vertical-align: top;
    display: inline-block;
    color: #999999;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    .ff-open-sans();
    margin-left: 5px;
    
    &:hover{
      color: darken(#999999,15);
      text-decoration: none;
    }
  }
}

.borderless tbody tr td, .borderless tbody tr th, .borderless thead tr th {
    border: none;
}

ul.pagination.custom-pagination{

  li{

    a{
      .btn-light-blue();
      .btn-light-blue();
      .btn-tight();
      font-weight: 700;
      .ff-open-sans();
      border: 1px solid @dark-blue;

      &:hover{
        .btn-tight();
        font-weight: 700;
        .ff-open-sans();
        border: 1px solid @dark-blue;
      }
    }

    &.active{

      a{
        background-color: @dark-blue;
        color: #FFF;     
        border: 1px solid @dark-blue;

        &:hover{
          background-color: @dark-blue;
        }
      }
    }

    &:last-child, &:first-child{

      a{
        border-radius: 0;
      }
    }

    &.disabled{
        
      a{
        background-color: @lighter-blue;
        border: 1px solid @darker-blue;

        &:hover{
          color: @dark-blue;
          border: 1px solid @darker-blue;
        }
      }
    }
  }
}


.btn-light-orange{
  .flat-button(@bg-color:@lighter-orange, @hover-bg-color: @orange, @border-color: @lighter-orange, @hover-border-color: darken(@lighter-orange,8), @double-border:false, @double-border-color:lighten(@lighter-orange,25), @round:0px, @text-color:@darker-orange, @hover-text-color:#FFF, @padding:10px 18px, @font-size:16px);
  font-weight: 600;
  line-height: 22px;
  text-shadow: none;
  .ff-open-sans();
  border: none;

  &:hover{
    text-shadow: none;
    border: none;
    color: #FFF;
  }
}

.map{
  width: 100%;

  #map-canvas{
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width : 1024px) {
  
  .container{
    width: 100%;
  }
}    