@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/Open-Sans-regular.eot');
  src: url('/assets/fonts/Open-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Open Sans'),
       local('Open-Sans-regular'),
       url('/assets/fonts/Open-Sans-regular.woff2') format('woff2'),
       url('/assets/fonts/Open-Sans-regular.woff') format('woff'),
       url('/assets/fonts/Open-Sans-regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/Open-Sans-700.eot');
  src: url('/assets/fonts/Open-Sans-700.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Bold'),
       local('Open-Sans-700'),
       url('/assets/fonts/Open-Sans-700.woff2') format('woff2'),
       url('/assets/fonts/Open-Sans-700.woff') format('woff'),
       url('/assets/fonts/Open-Sans-700.ttf') format('truetype'),
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/Open-Sans-300.eot');
  src: url('/assets/fonts/Open-Sans-300.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light'),
       local('Open-Sans-300'),
       url('/assets/fonts/Open-Sans-300.woff2') format('woff2'),
       url('/assets/fonts/Open-Sans-300.woff') format('woff'),
       url('/assets/fonts/Open-Sans-300.ttf') format('truetype'),
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url('/assets/fonts/Open-Sans-300italic.eot');
  src: url('/assets/fonts/Open-Sans-300italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light Italic'),
       local('Open-Sans-300italic'),
       url('/assets/fonts/Open-Sans-300italic.woff2') format('woff2'),
       url('/assets/fonts/Open-Sans-300italic.woff') format('woff'),
       url('/assets/fonts/Open-Sans-300italic.ttf') format('truetype'),
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('/assets/fonts/Open-Sans-600.eot');
  src: url('/assets/fonts/Open-Sans-600.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Semibold'),
       local('Open-Sans-600'),
       url('/assets/fonts/Open-Sans-600.woff2') format('woff2'),
       url('/assets/fonts/Open-Sans-600.woff') format('woff'),
       url('/assets/fonts/Open-Sans-600.ttf') format('truetype'),
}

@font-face {
  font-family: 'Lora';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/Lora-regular.eot');
  src: url('/assets/fonts/Lora-regular.eot?#iefix') format('embedded-opentype'),
       local('Lora'),
       local('Lora-regular'),
       url('/assets/fonts/Lora-regular.woff2') format('woff2'),
       url('/assets/fonts/Lora-regular.woff') format('woff'),
       url('/assets/fonts/Lora-regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'Lora';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/Lora-700.eot');
  src: url('/assets/fonts/Lora-700.eot?#iefix') format('embedded-opentype'),
       local('Lora Bold'),
       local('Lora-700'),
       url('/assets/fonts/Lora-700.woff2') format('woff2'),
       url('/assets/fonts/Lora-700.woff') format('woff'),
       url('/assets/fonts/Lora-700.ttf') format('truetype'),
}

@font-face {
  font-family: 'Lora';
  font-weight: 700;
  font-style: italic;
  src: url('/assets/fonts/Lora-700italic.eot');
  src: url('/assets/fonts/Lora-700italic.eot?#iefix') format('embedded-opentype'),
       local('Lora Bold Italic'),
       local('Lora-700italic'),
       url('/assets/fonts/Lora-700italic.woff2') format('woff2'),
       url('/assets/fonts/Lora-700italic.woff') format('woff'),
       url('/assets/fonts/Lora-700italic.ttf') format('truetype'),
}

@font-face {
  font-family: 'Lora';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/fonts/Lora-italic.eot');
  src: url('/assets/fonts/Lora-italic.eot?#iefix') format('embedded-opentype'),
       local('Lora Italic'),
       local('Lora-italic'),
       url('/assets/fonts/Lora-italic.woff2') format('woff2'),
       url('/assets/fonts/Lora-italic.woff') format('woff'),
       url('/assets/fonts/Lora-italic.ttf') format('truetype'),
}
